$grid-breakpoints: (
    xs: 0,
    sm: 600px,
    md: 768px,
    lg: 992px,
    xl: 1170px ) !default;

$container-max-widths: (
    sm: 600px,
    md: 768px,
    lg: 992px,
    xl: 1170px
) !default;

// Colors
$white: #FFFFFF;

$main-blue: #005CA9;
$brand-blue: #1e104a;
$main-dark: #0F0B1A;
$main-grey: #F2F2F2;
$secondary-grey: rgba(12, 11, 26, 0.4);
$tertiary-grey: #DADADA;
$main-yellow: #FFD546;
$green: #4AC753;
$grey: #BFBFBF;
$about-blue: #004AAD;

$grey-gradient: linear-gradient(90deg, #FAFAFA 0%, #DADADA 100%);

    //Buttons
    $anchor-dark: #0F0B1A;
    $anchor-dark-hover: #005BA9;

// Fonts
$poppins: 'Poppins', sans-serif;
