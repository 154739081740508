.fs-12{
    font-size: 12px;
}
.fs-13{
    font-size: 13px;
}
.fs-14{
    font-size: 14px;
}
.fs-16{
    font-size: 16px;
}
.fs-18{
    font-size: 18px;
}
.fs-20{
    font-size: 20px;
    &:before{
        font-size: 20px;
    }
}
.fs-24{
    font-size: 24px;
}
.fs-30{
    font-size: 30px;
    &:before{
        font-size: 30px;
    }
}
.fs-36{
    font-size: 36px;
}

.fs-40{
    font-size: 40px;
    &:before{
        font-size: 50px;
    }
}

.fs-48{
    font-size: 48px;
}

.fs-50{
    font-size: 50px;
    &:before{
        font-size: 50px;
    }
}

.fs-60{
    font-size: 60px;
    &:before{
        font-size: 60px;
    }
}

.fw-900{
    font-weight: 900;
}

.fs-md-20{
    @media screen and (min-width: 768px){
        font-size: 20px;
    }
}
.fs-md-24{
    @media screen and (min-width: 768px){
        font-size: 24px;
    }
}
.fs-md-36{
    @media screen and (min-width: 768px){
        font-size: 36px;
    }
}

.fs-lg-36{
    @media screen and (min-width: 1170px){
        font-size: 36px;
    }
}
