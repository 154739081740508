@import '~bootstrap/scss/bootstrap';

@import '~font-awesome/scss/font-awesome';

@import '~lightpick/css/lightpick.css';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100,300;400;500;700;900&display=swap');


@media only screen and (max-width: 768px) {
	.text-dark {
		color: #545454 !important;
	}
}
