html,body{
    font-size: 14px;
}

body{
    font-family: $poppins;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    font-weight: 300;
}

p{
    font-weight: 100;
}

strong{
    font-weight: 600;
}

.text-underline{
    text-decoration: underline;
}

// COLORS CLASSES
@import 'colors';

// Text decorations
.text-strikethrough{
    text-decoration: line-through;
}

// Font-sizes
@import 'font-sizes';

.hidden{
    display: none;
}

@media screen and (min-width: 768px){
    .w-md-auto{
        width: auto !important;
    }
}

@media screen and (min-width: 992px){
    .form-lg-sticky{
        position: sticky;
        top: 10px;
    }
}

.container{
    max-width: 1300px;
}

//NAV
.nav-link{
    color: $anchor-dark;
    padding: 0.5rem 0.75rem;
    font-weight: 500;
    &:hover,
    &:active,
    &:focus{
        color: $anchor-dark-hover;
    }
    &.active{
        font-weight: 700;
    }
}

.nav-tabs{
    border-bottom: none;
}
.nav-tabs .nav-link{
    background-color: $main-dark;
    color: $white;
    font-weight: 700;
    border-radius: 10px 10px 0 0;
    border: none;
    padding: 10px;
}
.nav-tabs .nav-item:not(:first-child){
    margin-left: 10px;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    color: $main-dark;
    background-color: $white;
}

.top-nav-section .nav-link{
    font-weight: 700;
}
.top-nav-section .nav-link.active{
    color: $main-blue;
}

#mobileNavBar{
    position: absolute;
    width: 100%;
    background: white;
    left: 0;
    z-index: 9999;
}

#mobileNavBar > ul{
    padding: 40px 20px;
}
#mobileNavBar > ul  .nav-link{
    font-weight: 700;
}

.dropdown-toggle:after{
    position: relative;
    top: 2px;
}

// Tab content
.tab-content{
    width: 100%;
    border-radius: 0 10px 10px 10px;
    padding: 20px;
    background-color: $white;
    color: $main-dark;
}

//Buttons
@import 'buttons';

// IMG
img{
    max-width: 100%;
}

//Fast search

.home-fast-search{
    .tab-content{
        padding: 20px 1.5rem;
    }
}

.form-group{
    &.is-invalid{
        .form-control{
            border-color: #dc3545;
            padding-right: calc(1.5em + 0.75rem) !important;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        }
    }
}

// Width

.w-75{
    width: 75%;
}

.w-66{
    width: 66.666%;
}

.w-60{
    width: 60%;
}

.w-40{
    width: 40%;
}

.w-33{
    width: 33.333%;
}

.w-25{
    width: 25%;
}

// Custom
@media screen and (min-width: 1200px){
    .displaced-img{
        padding-top: 50px;
        margin-bottom: 150px;
        padding-bottom: 50px;
    }
    .displaced-img img.displaced{
        margin-bottom: -100px;
    }
}

//Slick Slider

/* the slides */
.slick-slide {
    margin: 0 10px;
}
/* the parent */
.slick-list {
    margin: 0 -10px;
}
.slider-mobile-articles .slick-list{
    margin: 0;
}

.slick-dots li{
    width: 50px;
    height: 10px;
}
.slick-dots li button{
    width: 50px;
    height: 10px;
}
.slick-dots li button:before{
    width: 50px;
    height: 10px;
    font-size: 0;
    background-color: rgba(0,0,0,0.4);
    border-radius: 20px;
}
.slick-prev{
    left: 5px;
}
.slick-next{
    right: 5px;
}
.slick-prev, .slick-next{
    height: 35px;
    width: 40px;
    z-index: 9;
    &:before{
        font-size: 40px;
    }
}

// Card gradient
.gradient-on-img{
    overflow: hidden;
}
.gradient-on-img .card-body{
    position: relative;
}
.fade-to-grey-gradient{
    height: 200px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 100%;
    background: linear-gradient(0deg, $main-grey 0%, transparent 100%);
}

//Card
.card-img-top{
    height: auto;
    object-fit: cover;

    + .rounded-bottom{
        > *, .rounded{
            border-radius: 0!important;
        }
    }
}
.card-img-top.bigger-image{
    height: auto !important;
}

.card-img-left{
    border-top-left-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
    max-width: 50%;
    height: auto;

    + div{
        max-width: 50%;

        @media screen and (max-width: 767px){
            max-width: 100%;
        }
    }
}

.mobile-horizontal-scroll-cards.active > div.border{
    border-color: $main-yellow !important;
}

@media screen and (max-width: 991px){

}

@media screen and (max-width: 767px){

    .card-img-left{
        max-width: 100%;
        width: auto;
        height: auto;
    }

    .step-name{
        display: none;
    }
    .current{
        .step-name{
            display: block;
            white-space: nowrap;
            text-align: right;
            align-self: center;
        }
        &:first-child{
            .step-name{
                align-self: self-start;
            }
        }
        &:last-child{
            .step-name{
                align-self: self-end;
            }
        }
    }

    .mobile-horizontal-scroll{
        overflow-x: scroll;
        max-width: 100vw;
        white-space: nowrap;
    }
    .mobile-horizontal-scroll-cards{
        display: inline-block !important;
        width: 400px !important;
        max-width: 80vw;
        white-space: normal;
    }

    .mobile-header-img{
        min-height: 400px;
        object-fit: cover;
        object-position: left;
    }
}

.card-subtitle{
    margin-top: 0;
}

.card-price, .card-price-cut{
    font-weight: 100;
    line-height: 1;
}
.card-price-cut{
    font-weight: 100;
    margin: 10px 0 0;
}

// TIMELINE

//COUNTDOWN
.countdown-split .countdown-item{
    position: relative;
}
.countdown-split .countdown-item:not(:last-child):after{
    content: ":";
    position: absolute;
    right: -3px;
    top: 50%;
    transform: translateY(-53%);
    color: rgba(255,255,255, 0.2);
}

.font-weight-100{
    font-weight: 100;
}

.font-weight-medium{
    font-weight: 600;
}

//Select2

.form-control{
    height: 3.4rem;
    border-color: $grey;
    border-radius: 5px;
    color: $main-dark;
}

.select2-container--default .select2-selection--single{
    border: 1px solid #ced4da;
}
.select2-container .select2-selection--single{
    height: 3.4rem;
    border-radius: 5px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered{
    font-weight: 100;
    line-height: 3.4rem;
    padding: 0 10px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow{
    height: 100%;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b{
    border-width: 6px 5px 0 5px;
    border-color: #BFBFBF transparent transparent transparent;
    margin-left: -10px;
}

label{
    margin-bottom: 0.2rem;
}

input.form-control{
    &::-webkit-input-placeholder{
        opacity: 1;
        color: $grey;
        font-weight: 100;
    }
}

.email-icon input{
    padding: 15px 10px 15px 50px;
    height: auto;

    &::-webkit-input-placeholder{
        opacity: 1;
        color: $main-dark;
    }
}

.pin-icon input{
    padding: 15px 10px 15px 50px;
    height: auto;
}

.pin-icon:before{
    content: "\ee31";
    font-family: "fontello";
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    margin-right: 5px;
    position: absolute;
    left: 16px;
    top: 12px;
    color: $grey;
}

.email-icon:before{
    content: "\e81e";
    font-family: "fontello";
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    margin-right: 5px;
    left: 16px;
    top: 12px;
}

.calendar-icon:before{
    content: "\EC79";
    font-family: "fontello";
    font-size: 16px;
    color: #BFBFBF;
    position: absolute;
    left: 17px;
    top: 12px;
}
.calendar-icon input{
    font-weight: 100;
    line-height: 3.4rem;
    height: 3.4em;
    padding-left: 40px;
}

.clock-icon-select:before,
.pin-icon-select:before{
    content: "\E867";
    font-family: "fontello";
    color: $grey;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    position: absolute;
    left: 28px;
    top: 13px;
    z-index: 9;
}
.clock-icon-select .select2-container .select2-selection--single,
.pin-icon-select .select2-container .select2-selection--single{
    padding-left: 30px;
}

.pin-icon-select:before{
    content: "\eca7";
}

.icon-offset-l-13:before{
    top: 11px;
    left: 13px;
}
.icon-offset-l-24:before{
    left: 28px;
}

//Sticky buttons
.sticky-contact-btns{
    position: fixed;
    bottom: 10px;
    right: 0;
    z-index: 999;
    display: flex;
    flex-direction: row;
}
.sticky-contact-btns a:hover,
.sticky-contact-btns a:active,
.sticky-contact-btns a:focus{
    color: #fff;
}
.sticky-contact-btn-tel{
    background-color: #005BA9;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    text-decoration: none !important;
    height: 50px;
}
.sticky-contact-btn-tel:before{
    content: "\f095";
    position: relative;
    font: normal normal normal 30px/1 FontAwesome;

}
.sticky-contact-btn-tel span{
    position: relative;
    top: -12px;
    left: -6px;
}
.sticky-contact-btn-wapp{
    background-color: #005BA9;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    font: normal normal normal 30px/1 FontAwesome;
    margin-left: 15px;
    height: 50px;
}
.sticky-contact-btn-more{
    background-color: #005BA9;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    display: inline-block;
    font: normal normal normal 30px/1 FontAwesome;
    margin-left: 15px;
    height: 50px;
}

.ws-nowrap{
    white-space: nowrap;
}

.border-blue{
    border-style: solid;
    border-width: 1px;
    border-color: $main-blue;
}

.rounded{
    border-radius: 10px!important;
}

.rounded-circle{
    border-radius: 50px;
}

.image-header{
    position: relative;
}
.image-header-title{
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
}
.detail-car-slider{
    margin-bottom: 0 !important;
}
.detail-car-slider .slick-dots{
    bottom: 25px;
}

.home-car-slider .slick-dots{
    bottom: -40px;
}

.list-no-style{
    list-style: none;
}

.middle-aligned{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.nl-input{
    max-width: 340px;
    margin: 0 auto;
}

.car-specs-icons{

    > div{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    i{
        height: 35px;
        display: block;
        align-self: end;

        &:before{
            height: 35px;
            display: inline-block;
            vertical-align: top;
        }
    }

    p{
        width: 100%;
        margin-bottom: 0;
    }
}

.timeline-steps{

    div{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &:not(:first-child):before{
            content: "";
            width: 50%;
            position: absolute;
            height: 2px;
            background: rgba(0,0,0,0.4);
            top: 16px;
            left: 0;
            z-index: -1;
        }
        &:not(:last-child):after{
            content: "";
            width: 50%;
            position: absolute;
            height: 2px;
            background: rgba(0,0,0,0.4);
            top: 16px;
            right: 0;
            z-index: -1;
        }
        .step-no{
            display: inline-block;
            padding: 15px;
            width: 34px;
            height: 34px;
            vertical-align: middle;
            line-height: 1;
            color: rgba(0,0,0,0.4);
            font-weight: 700;
            border-radius: 25px;
            border: 2px solid rgba(0,0,0,0.4);
            background-color: #fff;
        }
        .step-name{
            font-size: 13px;
            font-weight: 500;
            color: rgba(0,0,0,0.4);
        }
        &.completed{
            &:before{
                background-color: #005BA9;
            }
            &:after{
                background-color: #005BA9;
            }
            .step-no{
                color: #fff;
                background-color: #005BA9;
                border: 2px solid #005BA9;
            }
            .step-name{
                color: #005BA9;
            }
        }
        &.current{
            &:before{
                background-color: #005BA9;
            }
            &:after{
                background-color: rgba(0,0,0,0.4);
            }
            .step-no{
                color: #005BA9;
                border: 2px solid #005BA9;
            }
            .step-name{
                color: #005BA9;
            }
        }
    }
}

#gmap_canvas{
    height: 500px;
    width: 100%;
    border-radius: 10px;
    @media screen and (max-width: 768px){
        height: 300px;
    }
}

#loader{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.6);
    justify-content: center;
    align-items: center;
}

.slick-next:before, .slick-prev:before {
    color: #aaabad !important;
}

.slick-next, .slick-next:focus, .slick-next:hover, .slick-prev, .slick-prev:focus, .slick-prev:hover {
    color: #505154 !important;
}

.team-banner-title{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px){
        width: 100%;
        height: auto;
    }
}




.list-monthly-rent{
    padding: 0;
    li{
        position: relative;
        padding-left: 35px;
        margin: 10px 0;
        color: #777;
        &:before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 25px;
            height: 25px;
            background-image: url('/images/design/tick_mark.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }
}

.nav-pills-monthly-rent{
    li.nav-item{
        border-radius: 0;
        border: none;
        border-bottom: 2px solid #777777;
        a.nav-link{
            font-size: 18px;
            color: #777777;
            font-weight: 700;
            background-color: transparent;
            padding: 10px 15px 10px 5px;
            @media screen and (max-width: 768px){
                font-size: 14px;
                padding: 10px 5px 10px 0;
            }
        }
        &.active{
            border-bottom: 2px solid #AE9A64;
            a.nav-link{
                color: #AE9A64;
            }
        }
    }
}

.dropdown-toggle-hover:hover + .dropdown-menu{
    display: block;
    margin-top: -2px;
}

.dropdown.dropdown-hover:hover .dropdown-menu{
    display: block;
    margin-top: -2px;
}

.lightpick__month-title > .lightpick__select{
    text-transform: capitalize;
}






.header-spacer{
    height: 5px;
}

@media screen and (max-width: 768px) {

    .header-spacer{
        height: 2px;
    }
    .mobile-fs-small {
        font-size: 8px !important;
    }

    .mobile-fs-text {
        font-size: 10px !important;
    }

    .mobile-fs-medium {
        font-size: 12px !important;
    }

    .mobile-fs-subtitle {
        font-size: 14px !important;
    }

    .mobile-fs-title {
        font-size: 16px !important;
    }

    .form-control {
        height: 3rem;
        font-size: 12px;
    }

    .select2-container .select2-selection--single {
        height: 3rem;
        font-size: 12px;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        line-height: 3rem;
        font-size: 12px;
    }

    .title-sizing {
        font-size: 10px;
    }
}

.image-title {
    border-radius: 15px;
    opacity: 0.698637;
    color: #000;
    font-weight: bold;
    font-size: 30px;
    background-color: rgba(246, 246, 246, 1);
    padding-top: 7%;
    padding-bottom: 4%;

}

.text-inchiriere {
    opacity: 1;
    color: rgba(34, 34, 34, 1);
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.3px;
    text-align: center;
    line-height: 32px;
    padding-left: 10%;
    padding-right: 10%;
}

.title-sizing {
    position: absolute;
    bottom: 15%;
    left: 5%;
    width: 27%;
}
.text-lined{
    position: relative;
}
.text-lined:after{
    content: "";
    position: absolute;
    width: 100%;
    left:0;
    top: calc(50% - 1px);
    height: 2px;
    background-color: #dc3545;
}
.text-lined-above{
    position: absolute;
    top: -20px;
    left: 0;
}
.text-offer-badge{
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 5px 10px;
    background: #005ca9;
    border-radius: 7px;
    color: #fff;
    line-height: 1;
}
