.bg-grey-gradient{
    background: $grey-gradient;
}
.bg-grey{
    background-color: $main-grey;
}
.bg-main-blue{
    background-color: $main-blue;
}
.bg-grey-tertiary {
    background-color: $tertiary-grey;
}

.bg-about-blue{
    background-color: $about-blue;
}


.text-dark{
    color: $main-dark;
}
.text-grey{
    color: $main-grey;
}
.text-grey-secondary{
    color: $secondary-grey;
}
.text-grey-tertiary{
    color: $tertiary-grey;
}
.text-blue{
    color: $main-blue;
}
.text-about-blue{
    color: $about-blue;
}

.text-shadowed{
    text-shadow: -1px -1px 3px #000, 1px 1px 12px #000;
}

.border{
    border-color: $main-grey !important;
}

.border-yellow{
    border-color: $main-yellow !important;
}

.icon-green{
    color: $green;
}

.icon-grey{
    color: $grey;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label a,
.form-check-label.is-invalid{
    color: #dc3545!important;
}

