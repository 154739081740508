.btn{
    font-size: 14px;
}

.btn-primary span,
.btn-outline-primary span,
.btn-yellow span,
.btn-new-green span,
.btn-another-primary span{
    z-index: 2;
    position: relative;
}

.btn-outline-primary{
    color: $main-blue;
    border-color: $main-blue;
    background-color: transparent;
    position: relative;
    overflow: hidden;
    font-weight: 500;
}
.btn-outline-primary:after{
    position: absolute;
    transition: .3s;
    content: "";
    width: 0;
    left: 50%;
    bottom: -2px;
    background: $main-blue;
    height: 120%;
    left: -10%;
    transform: skewX(15deg);
    z-index: 1;
}
.btn-outline-primary:hover:after,
.btn-outline-primary:active:after,
.btn-outline-primary:focus:after{
    left: -10%;
    width: 120%;
}
.btn-outline-primary:hover span,
.btn-outline-primary:active span,
.btn-outline-primary:focus span{
    color: $white;
}

.btn-primary{
    color: $white;
    background-color: $main-blue;
    border-color: $main-blue;
    font-weight: 600;
    position: relative;
    overflow: hidden;
}
.btn-primary:after{
    position: absolute;
    transition: .3s;
    content: "";
    width: 0;
    bottom: -2px;
    background: $white;
    height: 120%;
    left: -10%;
    transform: skewX(15deg);
    z-index: 1;
}
.btn-primary:hover:after,
.btn-primary:active:after,
.btn-primary:focus:after{
    left: -10%;
    width: 120%;
}
.btn-primary:hover span,
.btn-primary:active span,
.btn-primary:focus span{
    color: $main-blue;
}

.btn-another-primary{
    color: $white;
    background-color: $brand-blue;
    border-color: $brand-blue;
    font-weight: 600;
    position: relative;
    overflow: hidden;
}
.btn-another-primary:after{
    position: absolute;
    transition: .3s;
    content: "";
    width: 0;
    bottom: -2px;
    background: $white;
    height: 120%;
    left: -10%;
    transform: skewX(15deg);
    z-index: 1;
}
.btn-another-primary:hover:after,
.btn-another-primary:active:after,
.btn-another-primary:focus:after{
    left: -10%;
    width: 120%;
}
.btn-another-primary:hover span,
.btn-another-primary:active span,
.btn-another-primary:focus span{
    color: $brand-blue;
}


.btn-right-arrow{
    display: inline-block;
    padding-right: 15px;
    color: $main-blue;
    position: relative;
    padding-left: 0;
    font-weight: 600;
}
.btn-right-arrow:after{
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid $main-blue;
}

.btn-reset-filters{
     display: inline-block;
     padding-right: 15px;
     color: $danger;
     position: relative;
     padding-left: 0;
     font-weight: 600;
 }


.btn-yellow{
    color: $main-dark;
    background-color: $main-yellow;
    border-color: $main-yellow;
    font-weight: 600;
    position: relative;
    overflow: hidden;
}
.btn-yellow:after{
    position: absolute;
    transition: .3s;
    content: "";
    width: 0;
    bottom: -2px;
    background: $white;
    height: 120%;
    left: -10%;
    transform: skewX(15deg);
    z-index: 1;
}
.btn-yellow:hover:after,
.btn-yellow:active:after,
.btn-yellow:focus:after{
    left: -10%;
    width: 120%;
}
.btn-yellow:hover span,
.btn-yellow:active span,
.btn-yellow:focus span{
    //color: $white;
}

.btn-new-green{
    color: $white;
    background-color: #F0C661;
    border-color: #F0C661;
    font-weight: 600;
    position: relative;
    overflow: hidden;
}
.btn-new-green:after{
    position: absolute;
    transition: .3s;
    content: "";
    width: 0;
    bottom: -2px;
    background: $white;
    height: 120%;
    left: -10%;
    transform: skewX(15deg);
    z-index: 1;
}
.btn-new-green:hover:after,
.btn-new-green:active:after,
.btn-new-green:focus:after{
    left: -10%;
    width: 120%;
}
.btn-new-green:hover span,
.btn-new-green:active span,
.btn-new-green:focus span{
    color: #F0C661;
}


.btn-rounded{
    border-radius: 50px;
}


.btn-accordion{
    position: relative;
}
.btn-accordion:after{
    content: "\f078";
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    font: normal normal normal 14px/1 FontAwesome;
}
.btn-accordion.collapsed:after{
    content: "\f077";
}

.social-icon-btn{
    width: 40px;
    height: 40px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px !important;
}

.btn-xl{
    padding: 0.8rem 3rem;
}


.list-group-item{
    &.active{
        color: #343a40;
        border-color: #F2F2F2;
        background-color: #F2F2F2;
    }
}








.optional-checkbox{
    /* Base for label styling */
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
        position: absolute;
        left: 0;
        opacity: 0.01;
    }
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 2.3em;
        font-size: 1.05em;
        line-height: 1.7;
        cursor: pointer;
        height: 1em;
    }

    /* checkbox aspect */
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 1.4em;
        height: 1.4em;
        border: 1px solid #aaa;
        background: #FFF;
        border-radius: .2em;
        box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(203, 34, 237, .2);
        -webkit-transition: all .275s;
        transition: all .275s;
    }

    /* checked mark aspect */
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
        content: '✕';
        position: absolute;
        top: .48em;
        left: .12em;
        font-size: 1.375em;
        color: #FFD546;
        line-height: 0;
        -webkit-transition: all .2s;
        transition: all .2s;
        font-weight: 900;
    }

    /* checked mark aspect changes */
    [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0) rotate(45deg);
        transform: scale(0) rotate(45deg);
    }

    [type="checkbox"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
    }

    /* Disabled checkbox */
    [type="checkbox"]:disabled:not(:checked) + label:before,
    [type="checkbox"]:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #e9e9e9;
    }

    [type="checkbox"]:disabled:checked + label:after {
        color: #777;
    }

    [type="checkbox"]:disabled + label {
        color: #aaa;
    }

    /* Accessibility */
    [type="checkbox"]:checked:focus + label:before,
    [type="checkbox"]:not(:checked):focus + label:before {
        box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(203, 34, 237, .2);
    }

}

.onoff-checkbox {
    padding-left: 45px;
}
.onoff-checkbox input{
    opacity: 0;
}
.onoff-checkbox label.onOffLabel {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 32px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    cursor: pointer;
    background: transparent;
    border-radius: 5px;
    font-weight: bold;
    color: #FFF;
    -webkit-transition: background 0.3s, text-indent 0.3s;
    -moz-transition: background 0.3s, text-indent 0.3s;
    -o-transition: background 0.3s, text-indent 0.3s;
    transition: background 0.3s, text-indent 0.3s;
    text-indent: 8px;
}
.onoff-checkbox label.onOffLabel.is-invalid{
    background: $danger;
}
.onoff-checkbox.text_to_label label.onOffLabel{
    height: 20px;
}
.onoff-checkbox label.onOffLabel:after {
    content: 'Nu';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    font-size: 12px;
    color: #591717;
    z-index: 1;
}
.onoff-checkbox.text_to_label label.onOffLabel:after{
    line-height: 20px;
}
.onoff-checkbox label.onOffLabel.is-invalid:after{
    color: #FFF;
}
.onoff-checkbox input:checked + label.onOffLabel {
}
.onoff-checkbox input:checked + label.onOffLabel:after {
    content: 'Da';
}


