.lightpick{
    font-family: $poppins;
}

.lightpick__day.is-today{
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='rgba(255, 213, 70, .5)' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
    color: $main-yellow;
}

.lightpick__day.is-start-date.is-in-range,
.lightpick__day.is-end-date.is-in-range.is-flipped{
    border-color: $main-blue;
}
